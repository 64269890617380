import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';


const LuxC=()=> {
 
  return (
    <div className="home">
      <Navigation/>
      <Hero/>
      <section className="hero">
    <div className="container">
      <div className="row align-items-center g-4">
        <div className="col-lg-12 text-center">
          <h2>
            Discover the Height of Elegance at Australia’s Luxury Hotels
          </h2>
          <p className="m-0">
            Australia is home to some of the world’s most luxurious and refined hotels, offering the perfect blend of
            sophistication, exceptional service, and breathtaking locations. Whether you’re looking for a beachfront
            retreat, a city escape, or a tranquil hideaway, Australia’s luxury hotels promise an unforgettable
            experience.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="hotels container">
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The Langham, Melbourne
          </h3>
          <p>
            Situated along the Yarra River, The Langham is the epitome of luxury in Melbourne. Offering elegantly
            appointed rooms and suites with breathtaking views of the city skyline, the hotel’s refined atmosphere
            invites relaxation and indulgence. The Langham is home to a world-class day spa, a state-of-the-art fitness
            center, and a heated indoor pool, offering the perfect environment to unwind. Guests can also enjoy
            exquisite dining at Melba, renowned for its vibrant buffet and fine cuisine. With its location near
            Melbourne’s top attractions, shopping, and cultural landmarks, The Langham provides an elevated experience
            that combines comfort with convenience.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/langham.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Palazzo Versace, Gold Coast
          </h3>
          <p>
            A stunning blend of high fashion and luxury, Palazzo Versace offers guests an extraordinary stay on the
            beautiful Gold Coast. This iconic hotel features opulent rooms designed in signature Versace style, with
            marble floors, luxurious fabrics, and sophisticated color palettes. The hotel boasts a private marina,
            exquisite dining options, including the award-winning Vanitas restaurant, and an impressive outdoor pool
            overlooking the Broadwater. For those seeking an elevated lifestyle experience, Palazzo Versace is a true
            destination, offering exclusive boutiques, private yachts, and lavish spa treatments. Whether you're
            relaxing in the elegant interiors or exploring the vibrant Gold Coast, Palazzo Versace promises an
            unforgettable experience.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/palazzo.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The Olsen, Melbourne
          </h3>
          <p>
            Located in Melbourne’s fashionable South Yarra, The Olsen is a boutique hotel that offers a perfect blend of
            modern luxury and artistic flair. Named after Australian artist John Olsen, the hotel is a true reflection
            of creativity, with stunning artwork throughout and spacious, stylish rooms. The hotel features a rooftop
            pool with panoramic city views, a 24-hour fitness center, and sophisticated dining at Sarti, a contemporary
            Italian restaurant. The Olsen also provides a range of services for business and leisure travelers,
            including meeting rooms and event spaces. Its prime location places guests close to Melbourne’s top
            restaurants, shopping districts, and cultural hubs, making it an ideal choice for those seeking both luxury
            and convenience.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/olsen.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Qualia, Hamilton Island
          </h3>
          <p>
            Set in the heart of the Great Barrier Reef, Qualia on Hamilton Island is a secluded, ultra-luxurious resort
            offering an unparalleled escape. This exclusive retreat features private pavilions with stunning views of
            the Coral Sea and lush tropical gardens, designed to provide absolute privacy and comfort. The resort is
            renowned for its world-class service, with guests enjoying personalized concierge services, tailored
            experiences, and gourmet dining. With a focus on relaxation and rejuvenation, Qualia offers a full-service
            spa, yoga sessions, and access to the island’s pristine beaches. For adventure-seekers, the resort provides
            a range of water activities, including snorkeling, diving, and sailing. Ideal for honeymooners or anyone
            seeking a tranquil yet indulgent experience, Qualia delivers a serene and luxurious getaway in one of
            Australia’s most beautiful locations.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/qualia.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </section>

  <section className="services container">
    <div className="sect-name">
      <h2>
        Our Services
      </h2>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-sharp fa-solid fa-hotel"></i>
            <h3>
              Hotel Booking Assistance
            </h3>
          </div>
          <p>
            Our hotel booking assistance service makes planning your stay in Australia hassle-free. We provide easy
            access to the best hotels across major cities like Sydney, Melbourne, and Brisbane, offering detailed
            comparisons of amenities, prices, and locations. Whether you're looking for a luxury hotel with stunning
            views, a family-friendly resort, or a cozy boutique stay, our platform helps you find the perfect match for
            your needs. With trusted booking partners and the option to filter results based on your preferences, you
            can rest assured that your accommodation is just a few clicks away. Plus, enjoy special offers and exclusive
            discounts that ensure you get the best value for your stay.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-globe"></i>
            <h3>
              Tour and Activity Packages
            </h3>
          </div>
          <p>
            Enhance your Australian adventure with our curated tour and activity packages. We offer a wide range of
            experiences, from guided city tours in Sydney and Melbourne to nature excursions in the Outback or Great
            Barrier Reef. These packages are designed to give you a deeper connection to the culture, history, and
            natural beauty of Australia. Whether you're seeking an adrenaline rush with thrilling outdoor adventures or
            a relaxing day at local vineyards, there's something for every traveler. Our expert team carefully selects
            the best tours, ensuring high-quality experiences that suit your interests and schedule. Let us help you
            explore Australia like never before.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-money-bill"></i>
            <h3>
              Exclusive Deals
            </h3>
          </div>
          <p>
            Take advantage of the best offers with our exclusive deals and promotions, available only to our visitors.
            We’ve partnered with top hotels and resorts across Australia to bring you special discounts on
            accommodations, tours, and activities. Whether you're planning a luxury getaway in the heart of Sydney or a
            relaxing retreat on the Gold Coast, our deals ensure that you get top-quality experiences at unbeatable
            prices. From seasonal discounts to limited-time packages, we constantly update our offers to bring you the
            most current and exciting deals. Stay tuned for exclusive promotions and enjoy a premium Australian vacation
            without breaking the bank.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="book-resrvation">
    <div className="container">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-6 book-text text-center">
          <h2>
            Book your reservation!
          </h2>
          <p>
            Secure your stay at one of Australia’s premier hotels with just a few simple steps. Whether you're planning
            a luxurious getaway, a family vacation, or a business trip, our reservation process is quick and easy.
            Select your preferred dates, room type, and number of guests, and we'll handle the rest. Enjoy peace of mind
            knowing your accommodation is confirmed, with personalized services to make your stay unforgettable. Don't
            miss out on exclusive deals and special offers—book now and experience the best of Australian hospitality!
          </p>
        </div>
        <div className="col-lg-6">
          <form>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="name" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Enter your full name" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="email" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter your email" required/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-2">
                  <label for="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkin" className="form-label">Check-In Date</label>
                  <input type="date" className="form-control" id="checkin" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkout" className="form-label">Check-Out Date</label>
                  <input type="date" className="form-control" id="checkout" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="roomType" className="form-label">Room Type</label>
                  <select className="form-select" id="roomType" required>
                    <option selected disabled>Choose your room type</option>
                    <option value="standard">Standard Room</option>
                    <option value="deluxe">Deluxe Room</option>
                    <option value="suite">Suite</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="numGuests" className="form-label">Number of Guests</label>
                  <input type="number" className="form-control" id="numGuests" placeholder="Enter number of guests"
                    required/>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">Submit Reservation</button>
          </form>
        </div>

      </div>
    </div>
  </section>

      <Footer/>
    </div>
  );
}

export default LuxC;
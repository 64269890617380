import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';


const Best=()=> {
 
  return (
    <div className="home">
      <Navigation/>
      <Hero/>
 
      <section className="hero">
    <div className="container">
      <div className="row align-items-center g-4">
        <div className="col-lg-12 text-center">
          <h2>
            Explore the Best Hotels Across Australia for an Unforgettable Stay
          </h2>
          <p className="m-0">
            Australia is home to some of the world’s finest hotels, where luxury, comfort, and exceptional service
            combine to offer unforgettable experiences. Whether you're visiting bustling cities, serene coastal regions,
            or the heart of the Outback, Australia’s best hotels promise to provide the perfect backdrop for your
            adventure. Discover award-winning accommodations, innovative design, and world-class amenities that cater to
            both business and leisure travelers. Here are a few of the top hotels that define excellence in hospitality.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="hotels container">
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The Ritz-Carlton, Perth
          </h3>
          <p>
            The Ritz-Carlton in Perth offers five-star elegance with sweeping views of the Swan River. This luxurious
            hotel features modern design, spacious rooms, and a stunning outdoor pool. Guests can unwind at the hotel’s
            world-class spa or dine at the acclaimed Hearth Restaurant, known for its seasonal Australian cuisine.
            Perfectly positioned near the city’s top attractions, including Elizabeth Quay and Kings Park, The
            Ritz-Carlton is a haven of sophistication and comfort in one of Australia’s most dynamic cities.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/ritz.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Sofitel, Sydney Darling Harbour
          </h3>
          <p>
            With its sleek, modern architecture and unrivaled views of Darling Harbour, Sofitel Sydney offers guests a
            blend of French-inspired elegance and Australian hospitality. The hotel boasts a stunning rooftop pool, a
            fitness center with panoramic views, and a selection of fine dining options, including the signature Atelier
            by Sofitel restaurant. Conveniently located near the city’s top attractions, including the Sydney Opera
            House and Harbour Bridge, Sofitel Sydney is the ideal destination for both business and leisure travelers.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/sofitel.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The InterContinental, Sydney Double Bay
          </h3>
          <p>
            Set in one of Sydney’s most exclusive neighborhoods, The InterContinental Sydney Double Bay offers a refined
            escape in the heart of the city. With its boutique style, luxurious rooms, and rooftop pool, this hotel
            provides an intimate yet elevated experience. Guests can enjoy stunning views of the bay, world-class dining
            at the Bistro, and easy access to nearby beaches, shopping, and dining in the Double Bay area. The
            InterContinental offers a serene yet convenient retreat for those who seek comfort and style in Sydney.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/inter.jpeg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Park Hyatt, Melbourne
          </h3>
          <p>
            Located in the heart of Melbourne, Park Hyatt is known for its timeless elegance and contemporary design.
            With spacious rooms, luxurious amenities, and impeccable service, this five-star hotel is a true haven for
            both business and leisure travelers. The hotel’s prime location offers easy access to Melbourne’s renowned
            shopping districts, theaters, and cultural landmarks. Guests can relax at the tranquil rooftop pool or enjoy
            gourmet dining at The Park Restaurant, which serves modern Australian cuisine. The Park Hyatt Melbourne is
            the perfect blend of sophistication and convenience, offering an unforgettable stay.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/hyatt.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </section>

  <section className="services container">
    <div className="sect-name">
      <h2>
        Our Services
      </h2>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-sharp fa-solid fa-hotel"></i>
            <h3>
              Hotel Booking Assistance
            </h3>
          </div>
          <p>
            Our hotel booking assistance service makes planning your stay in Australia hassle-free. We provide easy
            access to the best hotels across major cities like Sydney, Melbourne, and Brisbane, offering detailed
            comparisons of amenities, prices, and locations. Whether you're looking for a luxury hotel with stunning
            views, a family-friendly resort, or a cozy boutique stay, our platform helps you find the perfect match for
            your needs. With trusted booking partners and the option to filter results based on your preferences, you
            can rest assured that your accommodation is just a few clicks away. Plus, enjoy special offers and exclusive
            discounts that ensure you get the best value for your stay.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-globe"></i>
            <h3>
              Tour and Activity Packages
            </h3>
          </div>
          <p>
            Enhance your Australian adventure with our curated tour and activity packages. We offer a wide range of
            experiences, from guided city tours in Sydney and Melbourne to nature excursions in the Outback or Great
            Barrier Reef. These packages are designed to give you a deeper connection to the culture, history, and
            natural beauty of Australia. Whether you're seeking an adrenaline rush with thrilling outdoor adventures or
            a relaxing day at local vineyards, there's something for every traveler. Our expert team carefully selects
            the best tours, ensuring high-quality experiences that suit your interests and schedule. Let us help you
            explore Australia like never before.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-money-bill"></i>
            <h3>
              Exclusive Deals
            </h3>
          </div>
          <p>
            Take advantage of the best offers with our exclusive deals and promotions, available only to our visitors.
            We’ve partnered with top hotels and resorts across Australia to bring you special discounts on
            accommodations, tours, and activities. Whether you're planning a luxury getaway in the heart of Sydney or a
            relaxing retreat on the Gold Coast, our deals ensure that you get top-quality experiences at unbeatable
            prices. From seasonal discounts to limited-time packages, we constantly update our offers to bring you the
            most current and exciting deals. Stay tuned for exclusive promotions and enjoy a premium Australian vacation
            without breaking the bank.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="book-resrvation">
    <div className="container">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-6 book-text text-center">
          <h2>
            Book your reservation!
          </h2>
          <p>
            Secure your stay at one of Australia’s premier hotels with just a few simple steps. Whether you're planning
            a luxurious getaway, a family vacation, or a business trip, our reservation process is quick and easy.
            Select your preferred dates, room type, and number of guests, and we'll handle the rest. Enjoy peace of mind
            knowing your accommodation is confirmed, with personalized services to make your stay unforgettable. Don't
            miss out on exclusive deals and special offers—book now and experience the best of Australian hospitality!
          </p>
        </div>
        <div className="col-lg-6">
          <form>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="name" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Enter your full name" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="email" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter your email" required/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-2">
                  <label for="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkin" className="form-label">Check-In Date</label>
                  <input type="date" className="form-control" id="checkin" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkout" className="form-label">Check-Out Date</label>
                  <input type="date" className="form-control" id="checkout" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="roomType" className="form-label">Room Type</label>
                  <select className="form-select" id="roomType" required>
                    <option selected disabled>Choose your room type</option>
                    <option value="standard">Standard Room</option>
                    <option value="deluxe">Deluxe Room</option>
                    <option value="suite">Suite</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="numGuests" className="form-label">Number of Guests</label>
                  <input type="number" className="form-control" id="numGuests" placeholder="Enter number of guests"
                    required/>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">Submit Reservation</button>
          </form>
        </div>

      </div>
    </div>
  </section>

      <Footer/>
    </div>
  );
}

export default Best;
import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';


const Home=()=> {
  
  return (
    <div className="home">
      <Navigation/>
      <Hero/>
      
  <section className="hotels container">
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The Star Sydney (Sydney, New South Wales)
          </h3>
          <p>
            The Star Sydney is a premier casino hotel offering a combination of luxury, entertainment, and dining.
            Located on the Darling Harbour waterfront, this iconic property features a world-class casino with a variety
            of gaming options, from poker to slot machines. Guests can indulge in exquisite restaurants, vibrant
            nightlife, and luxurious accommodations. The hotel also boasts a day spa and wellness center, ensuring total
            relaxation after a night of excitement. Whether you’re here for the thrill of the casino or a lavish stay,
            The Star Sydney delivers an unforgettable experience.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="/images/the-star-sydney-nightview.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Crown Towers (Melbourne, Victoria)
          </h3>
          <p>
            Crown Towers is the epitome of elegance and luxury in the heart of Melbourne. This five-star hotel offers
            stunning views of the city skyline and the Yarra River, along with easy access to the Crown Casino complex,
            one of the largest in the Southern Hemisphere. Guests can enjoy a range of high-end facilities including a
            world-class spa, gourmet dining, and an impressive pool deck. The casino itself features a variety of games,
            including table games, poker, and a wide selection of slots. With unparalleled service and comfort, Crown
            Towers is the perfect destination for those seeking excitement and luxury in one place.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="/images/Crown-Towers-Sydney-Sydney-Australia_Feat.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Jupiters Hotel & Casino (Gold Coast, Queensland)
          </h3>
          <p>
            Located on the beautiful Gold Coast, Jupiters Hotel & Casino offers an exciting escape where guests can
            enjoy a world-class casino experience along with luxurious resort-style accommodations. The casino features
            a broad range of gaming options, including roulette, blackjack, and an extensive poker room. Beyond gaming,
            Jupiters offers a range of fine dining options, from casual eateries to top-tier restaurants. The hotel also
            boasts a full-service spa and wellness center, along with a stunning outdoor pool. With a prime location and
            endless entertainment options, Jupiters is the perfect destination for those seeking both relaxation and
            excitement.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="/images/jupiters-hotel-casino-gold-coast-pic-61.jpeg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The Ville Resort-Casino (Townsville, Queensland)
          </h3>
          <p>
            The Ville Resort-Casino offers a relaxing yet thrilling stay on the stunning coastline of North Queensland.
            This waterfront resort features a wide variety of gaming options at its casino, including modern slot
            machines and table games. Guests can also enjoy exceptional dining at the hotel's renowned restaurants,
            which offer both casual and fine dining experiences. The Ville also boasts beautiful outdoor spaces,
            including a lagoon-style pool and lush gardens, perfect for unwinding after a day of excitement. With
            stunning views and a laid-back atmosphere, The Ville Resort-Casino provides the perfect mix of relaxation
            and entertainment.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="/images/resort-pool-and-splash.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </section>

  <section className="services container">
    <div className="sect-name">
      <h2>
        Our Services
      </h2>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-sharp fa-solid fa-hotel"></i>
            <h3>
              Hotel Booking Assistance
            </h3>
          </div>
          <p>
            Our hotel booking assistance service makes planning your stay in Australia hassle-free. We provide easy
            access to the best hotels across major cities like Sydney, Melbourne, and Brisbane, offering detailed
            comparisons of amenities, prices, and locations. Whether you're looking for a luxury hotel with stunning
            views, a family-friendly resort, or a cozy boutique stay, our platform helps you find the perfect match for
            your needs. With trusted booking partners and the option to filter results based on your preferences, you
            can rest assured that your accommodation is just a few clicks away. Plus, enjoy special offers and exclusive
            discounts that ensure you get the best value for your stay.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-globe"></i>
            <h3>
              Tour and Activity Packages
            </h3>
          </div>
          <p>
            Enhance your Australian adventure with our curated tour and activity packages. We offer a wide range of
            experiences, from guided city tours in Sydney and Melbourne to nature excursions in the Outback or Great
            Barrier Reef. These packages are designed to give you a deeper connection to the culture, history, and
            natural beauty of Australia. Whether you're seeking an adrenaline rush with thrilling outdoor adventures or
            a relaxing day at local vineyards, there's something for every traveler. Our expert team carefully selects
            the best tours, ensuring high-quality experiences that suit your interests and schedule. Let us help you
            explore Australia like never before.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-money-bill"></i>
            <h3>
              Exclusive Deals
            </h3>
          </div>
          <p>
            Take advantage of the best offers with our exclusive deals and promotions, available only to our visitors.
            We’ve partnered with top hotels and resorts across Australia to bring you special discounts on
            accommodations, tours, and activities. Whether you're planning a luxury getaway in the heart of Sydney or a
            relaxing retreat on the Gold Coast, our deals ensure that you get top-quality experiences at unbeatable
            prices. From seasonal discounts to limited-time packages, we constantly update our offers to bring you the
            most current and exciting deals. Stay tuned for exclusive promotions and enjoy a premium Australian vacation
            without breaking the bank.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="book-resrvation">
    <div className="container">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-6 book-text text-center">
          <h2>
            Book your reservation!
          </h2>
          <p>
            Secure your stay at one of Australia’s premier hotels with just a few simple steps. Whether you're planning
            a luxurious getaway, a family vacation, or a business trip, our reservation process is quick and easy.
            Select your preferred dates, room type, and number of guests, and we'll handle the rest. Enjoy peace of mind
            knowing your accommodation is confirmed, with personalized services to make your stay unforgettable. Don't
            miss out on exclusive deals and special offers—book now and experience the best of Australian hospitality!
          </p>
        </div>
        <div className="col-lg-6">
          <form>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="name" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Enter your full name" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="email" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter your email" required/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-2">
                  <label for="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkin" className="form-label">Check-In Date</label>
                  <input type="date" className="form-control" id="checkin" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkout" className="form-label">Check-Out Date</label>
                  <input type="date" className="form-control" id="checkout" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="roomType" className="form-label">Room Type</label>
                  <select className="form-select" id="roomType" required>
                    <option selected disabled>Choose your room type</option>
                    <option value="standard">Standard Room</option>
                    <option value="deluxe">Deluxe Room</option>
                    <option value="suite">Suite</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="numGuests" className="form-label">Number of Guests</label>
                  <input type="number" className="form-control" id="numGuests" placeholder="Enter number of guests"
                    required/>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">Submit Reservation</button>
          </form>
        </div>

      </div>
    </div>
  </section>
      <Footer/>
    </div>
  );
}

export default Home;
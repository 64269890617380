import React from 'react'

function Hero() {
  return (
    
  <section className="hero">
  <div className="container">
    <div className="row align-items-center g-4">
      <div className="col-lg-12 text-center">
        <h2>
          Experience the Best of Luxury and Entertainment at Australia’s Top Casino Hotels
        </h2>
        <p className="m-0">
          Indulge in the thrill of world-className gaming, luxurious accommodations, and exceptional dining at Australia’s
          premier casino hotels. Whether you’re seeking high-stakes excitement or a relaxing getaway, these
          destinations offer the perfect blend of entertainment and luxury. From the vibrant atmosphere of Sydney’s
          The Star to the elegance of Crown Towers in Melbourne, enjoy unforgettable experiences at every turn. Book
          your stay today and discover a unique blend of leisure, entertainment, and indulgence.
        </p>
      </div>
    </div>
  </div>
</section>
  )
}

export default Hero
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation=()=> {
  const [menuIsOpen,setIsMenu]=useState(false)
  
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-self">
    <div className="container">
      <Link className="navbar-brand" to="/">
        <h2>
          Australian<span>Booking</span>
        </h2>
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link " to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/casino-hotels">Casino Hotels</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/luxury-hotels">Luxury Hotels</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/best-hotels">Best Hotels</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/business-hotels">Business Hotels</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
}

export default Navigation;
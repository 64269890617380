import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';


const Bussines=()=> {
 
  return (
    <div className="home">
      <Navigation/>
      <Hero/>
      <section className="hero">
    <div className="container">
      <div className="row align-items-center g-4">
        <div className="col-lg-12 text-center">
          <h2>
            Top Business Hotels in Australia for Comfort, Convenience, and Productivity
          </h2>
          <p className="m-0">
            Australia’s business hotels combine luxury, convenience, and functionality to create the perfect environment
            for corporate travelers. Whether you're in the city for a meeting, a conference, or a business retreat,
            these hotels offer everything you need to work, relax, and network. With state-of-the-art facilities, prime
            locations, and exceptional service, they ensure that your business trip is both productive and comfortable.
            Here are some of the best business hotels in Australia that cater to the needs of professionals.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="hotels container">
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            The Westin, Sydney
          </h3>
          <p>
            Located in the heart of Sydney’s central business district, The Westin offers a seamless blend of luxury and
            practicality for business travelers. With modern meeting rooms, high-speed internet access, and
            state-of-the-art audiovisual equipment, the hotel is equipped for corporate meetings, conferences, and
            events. The Westin’s spacious rooms feature a comfortable work desk, ergonomic chairs, and plush amenities,
            ensuring a productive stay. Guests can also enjoy on-site dining at the renowned Mosaic restaurant or unwind
            at the hotel’s Heavenly Spa after a busy day of meetings.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/westin.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Rydges, Melbourne
          </h3>
          <p>
            A perfect choice for business travelers, Rydges Melbourne is located near the city’s central business and
            financial districts, providing easy access to key corporate offices and the Melbourne Convention and
            Exhibition Centre. The hotel offers a variety of meeting spaces, including large conference rooms and
            intimate boardrooms, all equipped with the latest technology and business services. In addition, guests can
            enjoy the convenience of 24-hour room service, a fully equipped business center, and an on-site restaurant
            serving fresh, local cuisine. After work, the hotel’s rooftop bar offers panoramic views of the Melbourne
            skyline for a relaxing end to the day.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/rydges.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Hilton, Sydney
          </h3>
          <p>
            A central hub for business travelers, Hilton Sydney offers stylish accommodations and a variety of business
            facilities. The hotel’s meeting and event spaces include flexible rooms equipped with high-speed Wi-Fi,
            projector systems, and video conferencing capabilities, making it an ideal choice for corporate events and
            meetings. With a full-service business center, concierge service, and 24-hour in-room dining, Hilton Sydney
            ensures your business trip runs smoothly. The hotel also features an on-site restaurant and bar, where you
            can relax and entertain clients after a long day of work.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/hilton.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="hotel-item mb-4">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-5">
          <h3>
            Pullman Quay Grand, Sydney Harbour
          </h3>
          <p>
            Offering spectacular views of Sydney Harbour, Pullman Quay Grand is an ideal choice for business travelers
            who seek a combination of luxury and functionality. The hotel offers modern conference and meeting spaces
            with panoramic views, perfect for events, conferences, and board meetings. Each suite features spacious work
            areas, high-speed internet, and an ergonomic workspace to ensure productivity. After a full day of meetings,
            guests can unwind by the harbor or dine at the hotel’s restaurant, which serves both local and international
            dishes.
          </p>
        </div>
        <div className="col-lg-7">
          <img src="images/pullman.jpg" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </section>

  <section className="services container">
    <div className="sect-name">
      <h2>
        Our Services
      </h2>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-sharp fa-solid fa-hotel"></i>
            <h3>
              Hotel Booking Assistance
            </h3>
          </div>
          <p>
            Our hotel booking assistance service makes planning your stay in Australia hassle-free. We provide easy
            access to the best hotels across major cities like Sydney, Melbourne, and Brisbane, offering detailed
            comparisons of amenities, prices, and locations. Whether you're looking for a luxury hotel with stunning
            views, a family-friendly resort, or a cozy boutique stay, our platform helps you find the perfect match for
            your needs. With trusted booking partners and the option to filter results based on your preferences, you
            can rest assured that your accommodation is just a few clicks away. Plus, enjoy special offers and exclusive
            discounts that ensure you get the best value for your stay.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-globe"></i>
            <h3>
              Tour and Activity Packages
            </h3>
          </div>
          <p>
            Enhance your Australian adventure with our curated tour and activity packages. We offer a wide range of
            experiences, from guided city tours in Sydney and Melbourne to nature excursions in the Outback or Great
            Barrier Reef. These packages are designed to give you a deeper connection to the culture, history, and
            natural beauty of Australia. Whether you're seeking an adrenaline rush with thrilling outdoor adventures or
            a relaxing day at local vineyards, there's something for every traveler. Our expert team carefully selects
            the best tours, ensuring high-quality experiences that suit your interests and schedule. Let us help you
            explore Australia like never before.
          </p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="service-item">
          <div className="service-name">
            <i className="fa-solid fa-money-bill"></i>
            <h3>
              Exclusive Deals
            </h3>
          </div>
          <p>
            Take advantage of the best offers with our exclusive deals and promotions, available only to our visitors.
            We’ve partnered with top hotels and resorts across Australia to bring you special discounts on
            accommodations, tours, and activities. Whether you're planning a luxury getaway in the heart of Sydney or a
            relaxing retreat on the Gold Coast, our deals ensure that you get top-quality experiences at unbeatable
            prices. From seasonal discounts to limited-time packages, we constantly update our offers to bring you the
            most current and exciting deals. Stay tuned for exclusive promotions and enjoy a premium Australian vacation
            without breaking the bank.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="book-resrvation">
    <div className="container">
      <div className="row flex-row-reverse g-4 align-items-center">
        <div className="col-lg-6 book-text text-center">
          <h2>
            Book your reservation!
          </h2>
          <p>
            Secure your stay at one of Australia’s premier hotels with just a few simple steps. Whether you're planning
            a luxurious getaway, a family vacation, or a business trip, our reservation process is quick and easy.
            Select your preferred dates, room type, and number of guests, and we'll handle the rest. Enjoy peace of mind
            knowing your accommodation is confirmed, with personalized services to make your stay unforgettable. Don't
            miss out on exclusive deals and special offers—book now and experience the best of Australian hospitality!
          </p>
        </div>
        <div className="col-lg-6">
          <form>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="name" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Enter your full name" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="email" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter your email" required/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-2">
                  <label for="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkin" className="form-label">Check-In Date</label>
                  <input type="date" className="form-control" id="checkin" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-2">
                  <label for="checkout" className="form-label">Check-Out Date</label>
                  <input type="date" className="form-control" id="checkout" required/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="roomType" className="form-label">Room Type</label>
                  <select className="form-select" id="roomType" required>
                    <option selected disabled>Choose your room type</option>
                    <option value="standard">Standard Room</option>
                    <option value="deluxe">Deluxe Room</option>
                    <option value="suite">Suite</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label for="numGuests" className="form-label">Number of Guests</label>
                  <input type="number" className="form-control" id="numGuests" placeholder="Enter number of guests"
                    required/>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">Submit Reservation</button>
          </form>
        </div>

      </div>
    </div>
  </section>

      <Footer/>
    </div>
  );
}

export default Bussines;
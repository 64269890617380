import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const Footer=()=> {
  const [cookieIsAccepted,setCookieIsAccepted]=useState(true)
  const [ageIsAccepted,setAgeIsAccepted]=useState(true)

  useEffect(()=>{
const value=localStorage.getItem("cookie")
const ageValue=localStorage.getItem("age")
if(value)
{
  setCookieIsAccepted(false)
}
if(ageValue)
  {
    setAgeIsAccepted(false)
  }
  },[])

const acceptCookie=()=>{
  setCookieIsAccepted(false)
  localStorage.setItem("cookie",true)
}
const acceptAge=()=>{
  setAgeIsAccepted(false)
  localStorage.setItem("age",true)
}

  return (
    <footer className="py-4 container">
    <p className="m-0">
      Copyright © 2024 australianbookingagency | All rights reserved

    </p>
  </footer>

  );
}

export default Footer;
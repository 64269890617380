import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

import 'bootstrap/dist/css/bootstrap.css'
import "./App.css"
import About from './pages/About';
import Casino from './pages/Casino';
import LuxC from './pages/LuxC';
import Best from './pages/Best';
import Bussines from './pages/Bussines';
import Contact from './pages/Contact';





const App=()=> {
  return (
    <Router>
      <div className="App">
       
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Casino-hotels" element={<Casino />} />
          <Route path="/luxury-hotels" element={<LuxC />} />
          <Route path="/best-hotels" element={<Best />} />
          <Route path="/business-hotels" element={<Bussines />} />
          <Route path="/contact-us" element={<Contact />} />
          
         
        </Routes>
      
      </div>
      
    </Router>
  );
}

export default App;
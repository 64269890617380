import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';


const Contact=()=> {
 
  return (
    <div className="home">
      <Navigation/>
      <Hero/>
      <section class="hero">
    <div class="container">
      <div class="row align-items-center g-4">
        <div class="col-lg-12 text-center">
          <h2>
            Get in Touch with Us
          </h2>
          <p class="m-0">
            We’re here to assist you with any questions or inquiries you may have. Whether you’re looking for more
            information about our featured hotels, need help with a booking, or want to learn more about the services we
            offer, our team is ready to help. Reach out to us through the contact form below or use the contact details
            provided. We aim to respond promptly to all inquiries and ensure that your experience with us is seamless
            and enjoyable. We look forward to hearing from you!
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="container py-5">
    <form action="#" method="POST">
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-2">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control" id="name" name="name"  required/>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-2">
            <label for="lastname" class="form-label">Last Name</label>
            <input type="text" class="form-control" id="lastname" name="lastname"  required/>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="mb-2">
            <label for="subject" class="form-label">Subject</label>
            <input type="text" class="form-control" id="subject" name="subject" required/>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="mb-2">
            <label for="message" class="form-label">Your Message</label>
            <textarea class="form-control" id="message" name="message" rows="5"  required></textarea>
          </div>

        </div>
      </div>
      <button type="submit" class="btn btn-primary">Send Message</button>
    </form>
  </section>

  <section class="services container">
    <div class="sect-name">
      <h2>
        Our Services
      </h2>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="service-item">
          <div class="service-name">
            <i class="fa-sharp fa-solid fa-hotel"></i>
            <h3>
              Hotel Booking Assistance
            </h3>
          </div>
          <p>
            Our hotel booking assistance service makes planning your stay in Australia hassle-free. We provide easy
            access to the best hotels across major cities like Sydney, Melbourne, and Brisbane, offering detailed
            comparisons of amenities, prices, and locations. Whether you're looking for a luxury hotel with stunning
            views, a family-friendly resort, or a cozy boutique stay, our platform helps you find the perfect match for
            your needs. With trusted booking partners and the option to filter results based on your preferences, you
            can rest assured that your accommodation is just a few clicks away. Plus, enjoy special offers and exclusive
            discounts that ensure you get the best value for your stay.
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="service-item">
          <div class="service-name">
            <i class="fa-solid fa-globe"></i>
            <h3>
              Tour and Activity Packages
            </h3>
          </div>
          <p>
            Enhance your Australian adventure with our curated tour and activity packages. We offer a wide range of
            experiences, from guided city tours in Sydney and Melbourne to nature excursions in the Outback or Great
            Barrier Reef. These packages are designed to give you a deeper connection to the culture, history, and
            natural beauty of Australia. Whether you're seeking an adrenaline rush with thrilling outdoor adventures or
            a relaxing day at local vineyards, there's something for every traveler. Our expert team carefully selects
            the best tours, ensuring high-quality experiences that suit your interests and schedule. Let us help you
            explore Australia like never before.
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="service-item">
          <div class="service-name">
            <i class="fa-solid fa-money-bill"></i>
            <h3>
              Exclusive Deals
            </h3>
          </div>
          <p>
            Take advantage of the best offers with our exclusive deals and promotions, available only to our visitors.
            We’ve partnered with top hotels and resorts across Australia to bring you special discounts on
            accommodations, tours, and activities. Whether you're planning a luxury getaway in the heart of Sydney or a
            relaxing retreat on the Gold Coast, our deals ensure that you get top-quality experiences at unbeatable
            prices. From seasonal discounts to limited-time packages, we constantly update our offers to bring you the
            most current and exciting deals. Stay tuned for exclusive promotions and enjoy a premium Australian vacation
            without breaking the bank.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="book-resrvation">
    <div class="container">
      <div class="row flex-row-reverse g-4 align-items-center">
        <div class="col-lg-6 book-text text-center">
          <h2>
            Book your reservation!
          </h2>
          <p>
            Secure your stay at one of Australia’s premier hotels with just a few simple steps. Whether you're planning
            a luxurious getaway, a family vacation, or a business trip, our reservation process is quick and easy.
            Select your preferred dates, room type, and number of guests, and we'll handle the rest. Enjoy peace of mind
            knowing your accommodation is confirmed, with personalized services to make your stay unforgettable. Don't
            miss out on exclusive deals and special offers—book now and experience the best of Australian hospitality!
          </p>
        </div>
        <div class="col-lg-6">
          <form>
            <div class="row">
              <div class="col-lg-6">
                <div class="mb-2">
                  <label for="name" class="form-label">Full Name</label>
                  <input type="text" class="form-control" id="name" placeholder="Enter your full name" required/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-2">
                  <label for="email" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="email" placeholder="Enter your email" required/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="mb-2">
                  <label for="phone" class="form-label">Phone Number</label>
                  <input type="tel" class="form-control" id="phone" placeholder="Enter your phone number" required/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-2">
                  <label for="checkin" class="form-label">Check-In Date</label>
                  <input type="date" class="form-control" id="checkin" required/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-2">
                  <label for="checkout" class="form-label">Check-Out Date</label>
                  <input type="date" class="form-control" id="checkout" required/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-3">
                  <label for="roomType" class="form-label">Room Type</label>
                  <select class="form-select" id="roomType"  required>
                    <option selected disabled>Choose your room type</option>
                    <option value="standard">Standard Room</option>
                    <option value="deluxe">Deluxe Room</option>
                    <option value="suite">Suite</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-3">
                  <label for="numGuests" class="form-label">Number of Guests</label>
                  <input type="number" class="form-control" id="numGuests" placeholder="Enter number of guests"
                    required/>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary w-100">Submit Reservation</button>
          </form>
        </div>

      </div>
    </div>
  </section>

      <Footer/>
    </div>
  );
}

export default Contact;